import 'bootstrap/dist/js/bootstrap.min'
import 'bootstrap/dist/css/bootstrap.min.css';
export const responses = {
    throwErr(err,title = `An Error Occurred`){
        if (!title) title = 'An Error Occurred';
        document.querySelector('#errorModal .modal-body').textContent = err;
        document.getElementById(`errorModalLabel`).innerHTML = title;
        document.getElementById("errorModalLabel").classList.add("text-danger");
        document.getElementById('errorModal-btn').click();
    },

    showSuccess(msg){
        document.querySelector('#errorModal .modal-body').textContent = msg;
        document.getElementById(`errorModalLabel`).innerHTML = "Success";
        document.getElementById("errorModalLabel").classList.add("text-success");
        document.getElementById('errorModal-btn').click();
    },

    showInfo(msg){
        document.querySelector('#errorModal .modal-body').textContent = msg;
        document.getElementById(`errorModalLabel`).innerHTML = "Information";
        document.getElementById("errorModalLabel").classList.add("text-info");
        document.getElementById('errorModal-btn').click();
    },

    showWarning(msg){
        document.querySelector('#errorModal .modal-body').textContent = msg;
        document.getElementById(`errorModalLabel`).innerHTML = "Warning";
        document.getElementById("errorModalLabel").classList.add("text-warning");
        document.getElementById('errorModal-btn').click();
    },
}
