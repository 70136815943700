import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from "@/components/HelloWorld.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: "home",
        component: HomeView,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
window.popStateDetected = false
window.addEventListener('popstate', () => {
    window.popStateDetected = true
})
export default router
