import copy from 'copy-to-clipboard';
import axios  from "axios";
import {utils} from "./utils";
import Vue from "vue";
import {errorHandler} from "@/global-variables/errorHandler";

// window.API = 'http://192.168.30.11:8080/subscription/api/'
window.API = `https://tom9.abc.co.zm/subscription/api/`;
window.copy = copy
window.axios = axios
window.errorHandler = errorHandler

Vue.prototype.$utils = utils;
window.$utils = utils;