import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/js/bootstrap.min'
import 'bootstrap/dist/css/bootstrap.min.css';
import './global-variables/global_variables'
// Import stylesheet

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
